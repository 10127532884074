<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Soccer Telegram Bot"/>
  </div>
  <span v-if="!isLoaded">Loading...</span>
  <telegram-login-temp
      mode="callback"
      telegram-login="SoccerTelegramBot"
      @loaded='loadedSoccerTelegramBotFunc'
      @callback="yourCallbackFunction"
  />
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { telegramLoginTemp } from 'vue3-telegram-login'

export default {
  name: 'HomeView',
  components: {
    telegramLoginTemp,
    HelloWorld
  },
  data(){
    return {
      isLoaded: false
    }
  },
  methods: {
    yourCallbackFunction (user) {
      // gets user as an input
      // id, first_name, last_name, username,
      // photo_url, auth_date and hash
      console.log(user)
      alert(user)
    },
    loadedSoccerTelegramBotFunc() {
      console.log('script is loaded')
      this.isLoaded.value = true
    }
  }
}
</script>
